import { TaskEventDto } from '@api/Api';
import Activity from '@components/Tasks/Activity';
import React, { memo, useCallback, useMemo } from 'react';
import { ReactComponent as ArrowSvg } from '@assets/icons/arrow-down.svg';
import './BulkActivity.less';
import classNames from 'classnames';
import moment from 'moment';
import useTypedDispatch from '@hooks/useTypedDispatch';
import { toggleTaskEventsGroup } from '@redux/reducers/tasks';

type BulkActivityProps = {
  activities: TaskEventDto[];
  isExpanded: boolean;
  taskId: string;
  isLastActivity: boolean;
};

export default memo(function BulkActivity({
  activities,
  isExpanded,
  taskId,
  isLastActivity
}: BulkActivityProps) {
  const dispatch = useTypedDispatch();

  const firstInBulkEventTime = useMemo(() => {
    return activities[0].eventTime;
  }, [activities]);
  const firstInBulkId = useMemo(() => {
    return activities[0].id;
  }, [activities]);

  const hideLastTimeline = useMemo(() => {
    return isLastActivity && !isExpanded;
  }, [isLastActivity, isExpanded]);

  const onToggleExpand = useCallback(() => {
    dispatch(toggleTaskEventsGroup({ taskId, eventId: firstInBulkId }));
  }, [dispatch, firstInBulkId, taskId]);

  return (
    <>
      <div
        onClick={onToggleExpand}
        className={classNames(
          'bulk-activity-row',
          hideLastTimeline && 'bulk-activity-row__hide-line'
        )}
      >
        <div className="bulk-activity-row__copy">
          <ArrowSvg className={classNames(isExpanded && 'svg-rotated')} />
          {activities.length} more updates
        </div>
        <div className="bulk-activity-row__copy bulk-activity-row__time">
          {moment(firstInBulkEventTime).format('MMM DD, h:mm a')}
        </div>
      </div>
      {isExpanded &&
        activities.map((it) => (
          <Activity key={it.id} isLastActivity={isLastActivity} activity={it} />
        ))}
    </>
  );
});
