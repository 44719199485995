import React, { memo } from 'react';
import { ReactComponent as ErrorIcon } from '@assets/icons/error.svg';
import './TaskInputError.less';

type TaskInputErrorProps = {
  text: string;
};

export default memo(function TaskInputError({ text }: TaskInputErrorProps) {
  return (
    <div className="task-input-error-container">
      <div className="task-input-error-container--icon">
        <ErrorIcon />
      </div>
      <div className="task-input-error-container--text">{text}</div>
    </div>
  );
});
