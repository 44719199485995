import AssigneesList from '@components/Tasks/AssigneesList';
import { useTypedSelector } from '@hooks';
import { UpdateTaskParams } from '@hooks/tasks';
import { taskSelector } from '@redux/selectors/tasks';
import classNames from 'classnames';
import React, { memo } from 'react';

interface TaskAssigneesProps {
  taskId: string;
  autoAssignMe?: boolean;
  onUpdate(payload: Omit<UpdateTaskParams, 'id'>): void;
}

export default memo(function TaskAssignees(props: TaskAssigneesProps) {
  const { taskId, onUpdate, autoAssignMe } = props;
  const editable = useTypedSelector((state) => {
    const task = taskSelector(state, { taskId });
    return !task.deletedAt && !task.archived && task.permissions.update;
  });
  return (
    <div
      className={classNames({
        'action-item-container': true,
        'deleted-task-cover-elements': !editable
      })}
    >
      <span className="item-name-title">Assignee</span>
      <AssigneesList
        maxCount={5}
        placement="bottomLeft"
        taskId={taskId}
        autoAssignMe={autoAssignMe}
        fetchOnOpen={false}
        onAdd={(member) =>
          onUpdate({
            assignees: { add: [{ ...member, hasAccessToTask: true }] }
          })
        }
        onRemove={(member) => onUpdate({ assignees: { remove: [member.id] } })}
      />
    </div>
  );
});
