import React, { memo, useState } from 'react';
import { useTypedSelector } from '@hooks';
import { taskSelector } from '@redux/selectors/tasks';
import TaskTags from '@components/Tasks/TaskModal/TaskTags';
import TaskHyperlink from '@components/Tasks/TaskModal/TaskHyperlink';
import TaskCustomText from '@components/Tasks/TaskModal/TaskCustomText';
import { ReactComponent as PlusSvg } from '@assets/icons/plus.svg';

interface TaskCustomFieldsProps {
  taskId: string;
}

export default memo(function TaskCustomFields(props: TaskCustomFieldsProps) {
  const { taskId } = props;
  const customFields = useTypedSelector(
    (state) => taskSelector(state, { taskId }).customFields
  );
  const [showCustomFields, setShowCustomFields] = useState(false);
  return (
    <>
      {customFields
        .filter((x) => x.type === 'tags' && x.value.length > 0)
        .map((x) => (
          <TaskTags key={x.id} fieldId={x.id} taskId={taskId} />
        ))}
      {customFields
        .filter(() => showCustomFields)
        .filter((x) => x.type !== 'tags')
        .map((x) => {
          if (x.type === 'url') {
            return <TaskHyperlink key={x.id} fieldId={x.id} taskId={taskId} />;
          }
          return <TaskCustomText key={x.id} fieldId={x.id} taskId={taskId} />;
        })}
      {customFields.some((x) => x.type !== 'tags') && (
        <div className="custom-fields-button-container">
          <span onClick={() => setShowCustomFields(!showCustomFields)}>
            {showCustomFields ? 'Less' : 'View all'}
          </span>
          {!showCustomFields && (
            <PlusSvg onClick={() => setShowCustomFields(true)} />
          )}
        </div>
      )}
    </>
  );
});
