import React, { memo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Tooltip } from 'antd';
import { ReactComponent as TaskAssetsLinkSvg } from '@assets/icons/task-assets-link-icon.svg';
import { ReactComponent as TaskCommentIconSvg } from '@assets/icons/task-from-comment-icon.svg';
import { ReactComponent as AnnotationIconSvg } from '@assets/icons/task-annotation-icon.svg';
import { useTypedSelector } from '@hooks';
import { taskSelector } from '@redux/selectors/tasks';

interface TaskAssetLinkProps {
  taskId: string;
  isNewTask: boolean;
}

export default memo(function TaskAssetLink(props: TaskAssetLinkProps) {
  const { taskId, isNewTask } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const asset = useTypedSelector(
    (state) => taskSelector(state, { taskId }).asset
  );
  const comment = useTypedSelector(
    (state) => taskSelector(state, { taskId }).assetVersionComment
  );

  if (isNewTask || !asset || asset.isDeleted) return null;

  const assetName = `${asset.selectedVersion.name}.${asset.selectedVersion.extension}`;
  return (
    <div
      className="assets-link-settings-container"
      onClick={() => {
        const isInMediaViewer = location.pathname.includes('/media-viewer/');
        let to = `/media-viewer/${asset.id}/${asset.selectedVersion.id}`;
        if (comment) to += `?commentId=${comment.id}`;
        navigate(to, {
          state: !isInMediaViewer
            ? {
                returnTo: location.pathname + location.search
              }
            : undefined
        });
      }}
    >
      {comment?.hasAnnotations === true && (
        <Tooltip
          overlayClassName="task-group-settings-hint"
          title="This task was created from a comment"
          trigger="hover"
          placement="top"
        >
          <AnnotationIconSvg
            style={{
              marginRight: 8,
              marginLeft: 0,
              height: 16,
              cursor: 'pointer'
            }}
          />
        </Tooltip>
      )}
      {comment?.hasAnnotations === false && (
        <Tooltip
          overlayClassName="task-group-settings-hint"
          title="This task was created from a comment"
          trigger="hover"
          placement="top"
        >
          <TaskCommentIconSvg
            style={{
              marginRight: 8,
              marginLeft: 0,
              height: 16,
              cursor: 'pointer'
            }}
          />
        </Tooltip>
      )}
      <Tooltip
        overlayClassName="long-text-hint"
        title={assetName}
        trigger="hover"
        placement="top"
      >
        <span>{assetName}</span>
      </Tooltip>
      <TaskAssetsLinkSvg />
    </div>
  );
});
