import Status from '@components/Tasks/Status';
import { statusMenu } from '@components/Tasks/Status/statusConfig';
import { nonNull } from '@helpers/non-null';
import { useTypedSelector } from '@hooks';
import { UpdateTaskParams } from '@hooks/tasks';
import { taskSelector } from '@redux/selectors/tasks';
import { Popover } from 'antd';
import classNames from 'classnames';
import React, { memo, useState } from 'react';

interface TaskStatusProps {
  taskId: string;
  onUpdate(payload: Omit<UpdateTaskParams, 'id'>): void;
}

export default memo(function TaskStatus(props: TaskStatusProps) {
  const { taskId, onUpdate } = props;
  const editable = useTypedSelector((state) => {
    const task = taskSelector(state, { taskId });
    return !task.deletedAt && !task.archived && task.permissions.update;
  });
  const value = useTypedSelector(
    (state) => taskSelector(state, { taskId }).status
  );
  const [open, setOpen] = useState(false);
  return (
    <div
      className={classNames({
        'action-item-container': true,
        'deleted-task-cover-elements': !editable
      })}
    >
      <Popover
        open={open}
        onOpenChange={setOpen}
        placement="leftTop"
        trigger="click"
        content={statusMenu(value, (newStatus) => {
          onUpdate({ status: nonNull(newStatus.status) });
          setOpen(false);
        })}
        overlayClassName="popover-container"
      >
        <span className="item-name-title">Status</span>
        <div style={{ marginTop: 8, cursor: 'pointer' }}>
          <Status status={value} />
        </div>
      </Popover>
    </div>
  );
});
