import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useRef
} from 'react';
import { Row, Col, Menu } from 'antd';
import { createPortal } from 'react-dom';
import { MemberDto } from '@api/Api';
import UserAvatar from '@components/UserAvatar';
import { Editor } from '@tiptap/core';
import { debounce } from '@helpers/debounce';
import classNames from 'classnames';

interface MentionListProps {
  items: MemberDto[];
  command: (props: { label: string; id: string }) => void;
}

export interface MentionListRef {
  onKeyDown: (e: KeyboardEvent) => boolean;
}

const MentionList = forwardRef<MentionListRef, MentionListProps>(
  ({ items, command }, ref) => {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const selectItem = (index: number) => {
      const item = items[index];
      const label = item?.user?.name ?? item?.invitationEmail;
      if (label) {
        command({
          label,
          id: item.id
        });
      }
    };

    const containerRef = useRef<HTMLDivElement>(null);

    const upHandler = () => {
      const index = (selectedIndex + items.length - 1) % items.length;
      setSelectedIndex(index);
      containerRef.current
        ?.querySelectorAll('.ant-menu-item')
        [index]?.scrollIntoView({ behavior: 'smooth' });
    };

    const downHandler = () => {
      const index = (selectedIndex + 1) % items.length;
      setSelectedIndex(index);
      containerRef.current
        ?.querySelectorAll('.ant-menu-item')
        [index]?.scrollIntoView({ behavior: 'smooth' });
    };

    const enterHandler = () => {
      selectItem(selectedIndex);
    };

    useEffect(() => setSelectedIndex(0), [items]);

    useImperativeHandle(ref, () => ({
      onKeyDown: (event) => {
        if (event.key === 'ArrowUp') {
          upHandler();
          return true;
        }
        if (event.key === 'ArrowDown') {
          downHandler();
          return true;
        }
        if (event.key === 'Enter') {
          enterHandler();
          return true;
        }
        return false;
      }
    }));

    const menuItems = items.map((x, i) => ({
      key: x.id,
      className: classNames({
        'rich_text_form_invite-item': true,
        'ant-menu-item-selected': selectedIndex === i
      }),
      onClick: () => selectItem(i),
      label: (
        <Row wrap={false} gutter={16} align="middle">
          <Col>
            <UserAvatar
              size="large"
              isActive={!x.invitationEmail}
              src={x.user?.picture.url || ''}
              userEmail={x.user?.email ?? x.invitationEmail ?? ''}
              userName={x.user?.name || ''}
            />
          </Col>
          <Col flex="auto">
            <div className="rich_text_form_invite-name">{x.user?.name}</div>
            <div className="rich_text_form_invite-email">
              {x.user?.email ?? x.invitationEmail}
            </div>
          </Col>
        </Row>
      )
    }));

    return (
      <div ref={containerRef} className="rich_text_form_invite">
        <Menu selectable={false} items={menuItems} />
      </div>
    );
  }
);

export interface RenderMentionListProps {
  container: Element;
  editor: Editor;
  clientRect?: (() => DOMRect | null) | null;
  query: string;
  visible: boolean;
  command: (props: { label: string; id: string }) => void;
  mentions: MemberDto[];
  onMention: (searchQuery: string) => void;
}

const RenderMentionList = forwardRef<MentionListRef, RenderMentionListProps>(
  (props: RenderMentionListProps, ref) => {
    const { query, visible, container, mentions, onMention } = props;
    const clientRect = useRef(props.clientRect);
    const [rect, setRect] = useState(
      clientRect.current ? clientRect.current() : null
    );
    const containerRef = useRef<HTMLDivElement>(null);

    const prevVisible = useRef(!visible);
    const prevQuery = useRef(query);

    if (visible !== prevVisible.current || query !== prevQuery.current) {
      prevVisible.current = visible;
      prevQuery.current = query;
      onMention(query);
    }

    useEffect(() => {
      let animationFrameId: number | undefined;
      const onScroll = debounce(
        (e: Event) => {
          animationFrameId = requestAnimationFrame(() => {
            if (e.target && !containerRef.current?.contains(e.target as any)) {
              if (clientRect.current) {
                setRect(clientRect.current());
              }
            }
          });
        },
        50,
        { maxWait: 100 }
      );
      document.addEventListener('scroll', onScroll, true);
      return () => {
        document.removeEventListener('scroll', onScroll);
        onScroll.cancel();
        if (animationFrameId) cancelAnimationFrame(animationFrameId);
      };
    }, []);

    if (!visible || !mentions.length) return null;

    return createPortal(
      <div
        className="ant-dropdown rich_text_form_overlay"
        style={{
          position: 'fixed',
          top: (rect?.top ?? 0) + 20,
          left: rect?.left ?? 0,
          zIndex: 2000
        }}
        ref={containerRef}
      >
        <MentionList ref={ref} {...props} items={mentions} />
      </div>,
      container
    );
  }
);

export default RenderMentionList;
