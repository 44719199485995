import React, { memo, useCallback, useMemo, useRef, useState } from 'react';
import { TaskEventDto, TaskItemMemberDto } from '@api/Api';
import RichTextForm from '@components/RichTextForm';
import Activity from '@components/Tasks/Activity';
import { useAuth } from '@hooks/useAuth';
import { useTypedSelector } from '@hooks';
import { taskSelector, taskStateSelector } from '@redux/selectors/tasks';
import { InfiniteScroll } from '@components/InfiniteScroll/InfiniteScroll';
import { useFetch } from '@hooks/useFetch';
import useTypedDispatch from '@hooks/useTypedDispatch';
import {
  fetchTaskCommentMentions,
  fetchTaskEvents
} from '@redux/actions/tasks';
import { debounce } from '@helpers/debounce';
import {
  loadMoreTaskEvents,
  setTaskCommentMentionsSearch
} from '@redux/reducers/tasks';
import { Skeleton } from 'antd';
import BulkActivity from '@components/Tasks/BulkActivity/BulkActivity';

interface TaskActivityProps {
  taskId: string;
  onComment: (text: string, mentions: TaskItemMemberDto[]) => void;
}

export default memo(function TaskActivity(props: TaskActivityProps) {
  const { taskId, onComment } = props;
  const dispatch = useTypedDispatch();
  const canComment = useTypedSelector((state) => {
    const task = taskSelector(state, { taskId });
    return !task.deletedAt && !task.archived && task.permissions.comment;
  });
  const mentions = useTypedSelector(
    (state) => taskStateSelector(state, { taskId }).commentMentions.list
  );
  const mentionsSearchQuery = useTypedSelector(
    (state) => taskStateSelector(state, { taskId }).commentMentions.searchQuery
  );
  const events = useTypedSelector(
    (state) => taskStateSelector(state, { taskId }).events.list
  );
  const hasMore = useTypedSelector(
    (state) => taskStateSelector(state, { taskId }).events.hasMore
  );
  const { user } = useAuth(true);
  const [visible, setVisible] = useState(true);

  const loading = useFetch({
    key: `task-events-${taskId}`,
    selector: (state) => taskStateSelector(state, { taskId }).events.fetch,
    fetch: (fetchType) => dispatch(fetchTaskEvents({ taskId, fetchType }))
  });

  useFetch({
    key: `task-comment-mentions-${taskId}`,
    selector: (state) =>
      taskStateSelector(state, { taskId }).commentMentions.fetch,
    fetch: (fetchType) =>
      dispatch(fetchTaskCommentMentions({ taskId, fetchType }))
  });

  const mentionsSearchQueryRef = useRef(mentionsSearchQuery);
  mentionsSearchQueryRef.current = mentionsSearchQuery;
  const onMention = useMemo(
    () =>
      debounce((searchQuery: string) => {
        if (searchQuery === mentionsSearchQueryRef.current) return;
        dispatch(
          setTaskCommentMentionsSearch({
            taskId,
            value: searchQuery
          })
        );
      }, 300),
    [taskId, dispatch]
  );

  const onLoadMore = useCallback(() => {
    dispatch(loadMoreTaskEvents({ taskId }));
  }, [taskId, dispatch]);

  return (
    <>
      <div className="task-description-container">
        <div className="comment-title-container">
          <span className="activity-title-text">WHAT&apos;S HAPPENED</span>
          <span
            className="activity-title-button"
            onClick={() => setVisible(!visible)}
          >
            {visible ? 'Hide log' : 'Show log'}
          </span>
        </div>
        {canComment && (
          <RichTextForm
            isShowButtons={true}
            isRequired={true}
            placeholder="Add a comment..."
            buttonSubmitText="Submit comment"
            onSubmit={(text, mentions) =>
              onComment(
                text,
                mentions.add.map((x) => ({ ...x, hasAccessToTask: true }))
              )
            }
            mentions={mentions}
            onMention={onMention}
            userPicture={user.pictureUrl ?? undefined}
            isCleanAfterSubmit={true}
          />
        )}
      </div>
      {visible && (
        <InfiniteScroll
          loading={loading}
          hasMore={hasMore}
          loader={
            <div>
              {events.length > 0 && <div className="activity-line" />}
              <div className="activity-container">
                <Skeleton.Avatar size="large" />
                <span className="activity-name">
                  <Skeleton.Input />
                </span>
              </div>
            </div>
          }
          next={onLoadMore}
        >
          <div style={{ marginTop: 24 }}>
            {events.map((x, i) => {
              if (x.isGrouped)
                return (
                  <BulkActivity
                    taskId={taskId}
                    isExpanded={x.isExpanded}
                    activities={x.it as TaskEventDto[]}
                    isLastActivity={i === events.length - 1}
                  />
                );
              const event = x.it as TaskEventDto;
              return (
                <div key={event.id}>
                  <Activity
                    isLastActivity={i === events.length - 1}
                    activity={event}
                  />
                </div>
              );
            })}
          </div>
        </InfiniteScroll>
      )}
    </>
  );
});
