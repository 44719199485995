import TaskInputError from '@components/Tasks/TaskModal/components/TaskInputError/TaskInputError';
import { useTypedSelector } from '@hooks';
import { UpdateTaskParams } from '@hooks/tasks';
import { taskSelector } from '@redux/selectors/tasks';
import { Input, Tooltip } from 'antd';
import classNames from 'classnames';
import React, { memo, useRef, useState } from 'react';

interface TaskNameProps {
  taskId: string;
  isNewTask: boolean;
  showErrors: boolean;
  onUpdate(payload: Omit<UpdateTaskParams, 'id'>): void;
  onFocus(): void;
}

export default memo(function TaskName(props: TaskNameProps) {
  const { taskId, onUpdate, isNewTask, showErrors, onFocus } = props;

  const editable = useTypedSelector((state) => {
    const task = taskSelector(state, { taskId });
    return !task.deletedAt && !task.archived && task.permissions.update;
  });
  const deleted = useTypedSelector(
    (state) => !!taskSelector(state, { taskId }).deletedAt
  );
  const value = useTypedSelector(
    (state) => taskSelector(state, { taskId }).name
  );

  const [currentValue, setCurrentValue] = useState(value);
  const prevValue = useRef(value);
  if (value !== prevValue.current) {
    prevValue.current = value;
    setCurrentValue(value);
  }

  const [focus, setFocus] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  return (
    <>
      {editable && (
        <Tooltip
          open={tooltipOpen}
          onOpenChange={setTooltipOpen}
          overlayClassName="long-text-hint"
          title={currentValue}
          trigger={focus ? [] : ['hover']}
          placement="topLeft"
        >
          <div
            style={{ width: '100%' }}
            className={classNames({ 'selected-task-name-input': !isNewTask })}
          >
            <Input.TextArea
              id="TASK-NAME-INPUT"
              size="large"
              placeholder="Task name"
              value={currentValue}
              onChange={(e) => {
                setCurrentValue(e.target.value);
                if (isNewTask) onUpdate({ name: e.target.value });
              }}
              autoSize={{ minRows: 1, maxRows: 2 }}
              onFocus={() => {
                setFocus(true);
                setTooltipOpen(false);
                onFocus();
              }}
              onBlur={() => {
                setFocus(false);
                if (currentValue !== value) {
                  onUpdate({ name: currentValue });
                }
              }}
              maxLength={60}
              status={showErrors && !currentValue ? 'error' : undefined}
            />
            {showErrors && !currentValue && (
              <TaskInputError text="Name is required" />
            )}
          </div>
        </Tooltip>
      )}
      {!editable && (
        <div className="deleted-task-name-container">
          <span>{currentValue}</span>
          {deleted && (
            <div>
              <span>Deleted</span>
            </div>
          )}
        </div>
      )}
    </>
  );
});
