import React, { memo } from 'react';
import classNames from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTypedSelector } from '@hooks';
import { taskSelector } from '@redux/selectors/tasks';

interface TaskBreadcrumbsProps {
  taskId: string;
  isNewTask: boolean;
}

export default memo(function TaskBreadcrumbs(props: TaskBreadcrumbsProps) {
  const { taskId, isNewTask } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const campaign = useTypedSelector(
    (state) => taskSelector(state, { taskId }).campaign
  );
  const asset = useTypedSelector(
    (state) => taskSelector(state, { taskId }).asset
  );
  const board = useTypedSelector(
    (state) => taskSelector(state, { taskId }).board
  );
  if (isNewTask) return null;
  return (
    <div className="task-breadcrumbs-container">
      <span
        className="task-breadcrumbs"
        onClick={() =>
          navigate(
            `/campaigns/single/${campaign.id}?tab=tasks&boardId=${board.id}`
          )
        }
      >
        {campaign.name}
      </span>
      {!!asset &&
        asset.path.map((x) => (
          <span
            key={x.id}
            className="task-breadcrumbs"
            onClick={() => navigate(`/campaigns/single/${campaign.id}/${x.id}`)}
          >
            {`/${x.name}`}
          </span>
        ))}
      {!!asset && (
        <span
          className={classNames({
            'task-breadcrumbs': true,
            'b-line-through': asset.isDeleted
          })}
          onClick={() => {
            if (!asset || asset.isDeleted) return;
            navigate(`/media-viewer/${asset.id}/${asset.selectedVersion.id}`, {
              state: {
                returnTo: location.pathname + location.search
              }
            });
          }}
        >
          /
          <span>
            {asset.selectedVersion.name}.{asset.selectedVersion.extension}
          </span>
        </span>
      )}
    </div>
  );
});
