import Priority from '@components/Tasks/Priority';
import { priorityMenu } from '@components/Tasks/Priority/priorityConfig';
import { Popover } from 'antd';
import classNames from 'classnames';
import React, { memo, useState } from 'react';
import { ReactComponent as CloseSvg } from '@assets/icons/cross.svg';
import { ReactComponent as PlusCircleSvg } from '@assets/icons/plus-circle.svg';
import { UpdateTaskParams } from '@hooks/tasks';
import { useTypedSelector } from '@hooks';
import { taskSelector } from '@redux/selectors/tasks';

interface TaskPriorityProps {
  taskId: string;
  onUpdate(payload: Omit<UpdateTaskParams, 'id'>): void;
}

export default memo(function TaskPriority(props: TaskPriorityProps) {
  const { taskId, onUpdate } = props;
  const editable = useTypedSelector((state) => {
    const task = taskSelector(state, { taskId });
    return !task.deletedAt && !task.archived && task.permissions.update;
  });
  const value = useTypedSelector(
    (state) => taskSelector(state, { taskId }).priority
  );
  const [open, setOpen] = useState(false);
  return (
    <div
      className={classNames({
        'action-item-container': true,
        'deleted-task-cover-elements': !editable
      })}
    >
      <Popover
        open={open}
        onOpenChange={setOpen}
        placement="leftTop"
        trigger="click"
        content={priorityMenu(value ?? null, (newPriority) => {
          setOpen(false);
          onUpdate({ priority: newPriority.priority ?? null });
        })}
        overlayClassName="popover-container"
      >
        <span className="item-name-title">Priority</span>
        {!!value && (
          <div style={{ display: 'flex' }}>
            <Priority
              priority={value}
              classNamePriorityContainer="task-modal-priority"
            />
            <CloseSvg
              onClick={(event: any) => {
                event.stopPropagation();
                onUpdate({ priority: null });
              }}
            />
          </div>
        )}
        {editable && !value && <PlusCircleSvg />}
      </Popover>
    </div>
  );
});
