import RichTextForm from '@components/RichTextForm';
import { useTypedSelector } from '@hooks';
import { UpdateTaskParams } from '@hooks/tasks';
import { useFetch } from '@hooks/useFetch';
import useTypedDispatch from '@hooks/useTypedDispatch';
import { fetchTaskDescriptionMentions } from '@redux/actions/tasks';
import { setTaskDescriptionMentionsSearch } from '@redux/reducers/tasks';
import { taskSelector, taskStateSelector } from '@redux/selectors/tasks';
import { Tooltip, TooltipProps } from 'antd';
import classNames from 'classnames';
import React, { memo, useRef, useState, useCallback } from 'react';

interface TaskDescriptionProps {
  taskId: string;
  onUpdate(payload: Omit<UpdateTaskParams, 'id'>): void;
  descriptionPlaceholder: string | null;
  getPopupContainer: TooltipProps['getPopupContainer'];
}

export default memo(function TaskDescription(props: TaskDescriptionProps) {
  const { taskId, onUpdate, descriptionPlaceholder, getPopupContainer } = props;
  const dispatch = useTypedDispatch();
  const editable = useTypedSelector((state) => {
    const task = taskSelector(state, { taskId });
    return !task.deletedAt && !task.archived && task.permissions.update;
  });
  const value = useTypedSelector(
    (state) => taskSelector(state, { taskId }).description
  );
  const mentions = useTypedSelector(
    (state) => taskStateSelector(state, { taskId }).descriptionMentions.list
  );
  const mentionsSearchQuery = useTypedSelector(
    (state) =>
      taskStateSelector(state, { taskId }).descriptionMentions.searchQuery
  );
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [focus, setFocus] = useState(false);

  useFetch({
    key: `task-description-mentions-${taskId}`,
    selector: (state) =>
      taskStateSelector(state, { taskId }).descriptionMentions.fetch,
    fetch: (fetchType) =>
      dispatch(fetchTaskDescriptionMentions({ taskId, fetchType }))
  });

  const mentionsSearchQueryRef = useRef(mentionsSearchQuery);
  mentionsSearchQueryRef.current = mentionsSearchQuery;
  const onMention = useCallback(
    (searchQuery: string) => {
      if (searchQuery === mentionsSearchQueryRef.current) return;
      dispatch(
        setTaskDescriptionMentionsSearch({
          taskId,
          value: searchQuery
        })
      );
    },
    [taskId, dispatch]
  );

  return (
    <div
      className={classNames({
        'task-description-container': true,
        'b-main-description': true,
        'deleted-task-cover-elements': !editable
      })}
    >
      <span>Description</span>
      <Tooltip
        open={tooltipOpen}
        overlayClassName="task-group-settings-hint"
        placement="topLeft"
        overlayInnerStyle={{ transform: 'translateY(10px)' }}
        title="Edit description"
        trigger="hover"
        onOpenChange={(open) => setTooltipOpen(open && !focus)}
        getPopupContainer={getPopupContainer}
      >
        <div>
          <RichTextForm
            isShowButtons={true}
            value={value}
            submitOnBlur
            hideSubmitButton
            onFocus={() => {
              setFocus(true);
              setTooltipOpen(false);
            }}
            onBlur={() => setFocus(false)}
            placeholder={
              descriptionPlaceholder ||
              'Time to get things done. Describe your task'
            }
            onSubmit={(text, mentions) => {
              if (text !== value) {
                onUpdate({
                  description: text,
                  descriptionMentions: {
                    add: mentions.add.map((x) => ({
                      ...x,
                      hasAccessToTask: true
                    })),
                    remove: mentions.remove
                  }
                });
              }
            }}
            mentions={mentions}
            onMention={onMention}
          />
        </div>
      </Tooltip>
    </div>
  );
});
