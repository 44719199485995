import { useCallback } from 'react';
import { useTaskAttachmentsUppy } from '@context/TaskAttachmentsUppyContext';
import { getFiles } from '@helpers/getFiles';

export function useAddAttachment(taskId: string, isNewTask: boolean) {
  const uppy = useTaskAttachmentsUppy();
  const addAttachment = useCallback(
    async (
      items:
        | File
        | File[]
        | FileList
        | DataTransferItem
        | DataTransferItem[]
        | DataTransferItemList
    ) => {
      const files = await getFiles(items);
      uppy.addFiles(
        files.map((file) => {
          const meta: Record<string, unknown> = {
            relativePath: new Date().toISOString(),
            taskId,
            url: URL.createObjectURL(file)
          };
          if (!isNewTask) meta.readyForUpload = true;
          return {
            name: file.name,
            type: file.type,
            data: file,
            meta
          };
        })
      );
      if (!isNewTask) {
        uppy.upload();
      }
    },
    [uppy, taskId, isNewTask]
  );
  return addAttachment;
}
