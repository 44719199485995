import { useTypedSelector } from '@hooks';
import { taskSelector } from '@redux/selectors/tasks';
import { Tooltip } from 'antd';
import classNames from 'classnames';
import React, { memo } from 'react';

interface TaskTagsProps {
  taskId: string;
  fieldId: string;
}

export default memo(function TaskTags(props: TaskTagsProps) {
  const { taskId, fieldId } = props;
  const editable = useTypedSelector((state) => {
    const task = taskSelector(state, { taskId });
    return !task.deletedAt && !task.archived && task.permissions.update;
  });
  const field = useTypedSelector((state) =>
    taskSelector(state, { taskId }).customFields.find((x) => x.id === fieldId)
  );
  if (!field) return null;
  return (
    <div
      className={classNames({
        'action-item-container': true,
        'deleted-task-cover-elements': !editable
      })}
      style={{ width: '100%' }}
    >
      <span className="item-name-title">{field.name}</span>
      <div className="tag-list-container">
        {Array.isArray(field.value) &&
          field.value.map((item: string) => (
            <Tooltip
              key={item}
              overlayClassName="long-text-hint"
              title={item}
              trigger="hover"
              overlayInnerStyle={{ maxWidth: 200 }}
            >
              <span>{item}</span>
            </Tooltip>
          ))}
      </div>
    </div>
  );
});
