import React from 'react';
import { Form, Input, Row, Col, Button } from 'antd';

import './LinkModalContent.less';

interface ILinkModalProps {
  onClose(): void;
  onSubmit(data: { href: string; text: string }): void;
}

function LinkModalContent({ onClose, onSubmit }: ILinkModalProps) {
  const [form] = Form.useForm();

  const onSubmitForm = (data: { href: string; text: string }) => {
    onSubmit(data);
    form.resetFields();
  };

  const onCloseModal = () => {
    onClose();
    form.resetFields();
  };

  return (
    <Form
      form={form}
      onFinish={onSubmitForm}
      layout="vertical"
      className="rich_text_form_link-modal_form"
    >
      <Form.Item
        label="Web address"
        name="href"
        rules={[{ required: true, message: 'Web address required.' }]}
      >
        <Input placeholder="www.example.com" size="large" />
      </Form.Item>
      <Form.Item
        label="Text to display"
        name="text"
        rules={[{ required: true, message: 'Text to display required.' }]}
      >
        <Input placeholder="Enter text" size="large" />
      </Form.Item>
      <Row
        className="rich_text_form_link-modal_form-bottom"
        wrap={false}
        align="middle"
        justify="end"
        gutter={12}
      >
        <Col>
          <Button
            type="text"
            className="rich_text_form_btn"
            htmlType="button"
            onClick={onCloseModal}
          >
            Cancel
          </Button>
        </Col>
        <Col>
          <Button
            type="primary"
            className="rich_text_form_btn"
            htmlType="submit"
          >
            Save
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

export default LinkModalContent;
