import { useTypedSelector } from '@hooks';
import { taskSelector } from '@redux/selectors/tasks';
import Paragraph from 'antd/lib/typography/Paragraph';
import classNames from 'classnames';
import React, { memo } from 'react';

interface TaskCustomTextProps {
  taskId: string;
  fieldId: string;
}

export default memo(function TaskCustomText(props: TaskCustomTextProps) {
  const { taskId, fieldId } = props;
  const editable = useTypedSelector((state) => {
    const task = taskSelector(state, { taskId });
    return !task.deletedAt && !task.archived && task.permissions.update;
  });
  const field = useTypedSelector((state) =>
    taskSelector(state, { taskId }).customFields.find((x) => x.id === fieldId)
  );
  if (!field) return null;
  return (
    <div
      className={classNames({
        'action-item-container': true,
        'deleted-task-cover-elements': !editable
      })}
      style={{ width: '100%' }}
    >
      <span>{field.name}</span>
      <div className="custom-text-fields" id="custom-text-wrapper">
        <Paragraph
          ellipsis={{
            rows: 4,
            expandable: true
          }}
        >
          {field.value}
        </Paragraph>
      </div>
    </div>
  );
});
