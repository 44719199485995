import { Menu, Popover, PopoverProps, Progress, Tooltip, Upload } from 'antd';
import classNames from 'classnames';
import React, { memo, useMemo, useState } from 'react';
import { useTypedSelector } from '@hooks';
import { taskSelector } from '@redux/selectors/tasks';
import { ReactComponent as ActionSvg } from '@assets/icons/action.svg';
import { ReactComponent as AddNewAttachmentsSvg } from '@assets/icons/add-new-attachments-plus.svg';
import AudioPlaceholderImg from '@assets/images/upload/audio_asset.png';
import FilePlaceholderImg from '@assets/images/upload/file_asset.png';
import VideoPlaceholderImg from '@assets/images/upload/video_asset.png';
import { deleteTaskAttachment } from '@redux/actions/tasks';
import { useTaskAttachmentsUppy } from '@context/TaskAttachmentsUppyContext';
import { MenuItemType } from 'antd/lib/menu/hooks/useItems';
import { useDispatch } from 'react-redux';
import { useAddAttachment } from '@components/Tasks/TaskModal/hooks/useAddAttachment';
import { useUppyState } from '@hooks/useUppyState';

interface TaskAttachmentsProps {
  taskId: string;
  isNewTask: boolean;
  getPopupContainer: PopoverProps['getPopupContainer'];
}

const getPreview = (file: { mimeType: string; url: string }) => {
  return file.mimeType.startsWith('image/')
    ? { url: file.url, mimeType: file.mimeType }
    : file?.mimeType?.startsWith('audio/')
    ? { url: AudioPlaceholderImg, mimeType: 'image/png' }
    : file.mimeType.startsWith('video/')
    ? { url: VideoPlaceholderImg, mimeType: 'image/png' }
    : { url: FilePlaceholderImg, mimeType: 'image/png' };
};

function useAttachments(taskId: string) {
  const dispatch = useDispatch();
  const uppy = useTaskAttachmentsUppy();
  const uppyState = useUppyState(uppy);

  const attachments = useTypedSelector(
    (state) => taskSelector(state, { taskId }).attachments
  );

  const uppyAttachments = useMemo(
    () => uppy.getFiles().filter((x) => x.meta.taskId === taskId),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [uppy, uppyState, taskId]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const previewUrls = useMemo(() => new Map<string, string>(), [taskId]);
  return useMemo(() => {
    uppyAttachments
      .filter((x) => x.meta.attachmentId)
      .forEach((x) =>
        previewUrls.set(x.meta.attachmentId as string, x.meta.url as string)
      );

    const attachmentIds = attachments.map((x) => x.id);
    uppyAttachments
      .filter((x) => attachmentIds.includes(x.meta.attachmentId as string))
      .forEach((x) => uppy.removeFile(x.id));

    const pendingAttachments = uppyAttachments
      .filter((x) => !(x as any).error && !x.isPaused)
      .filter((x) => !attachmentIds.includes(x.meta.attachmentId as string));

    return [
      ...attachments.map((x) => {
        return {
          ...x,
          previewUrl: previewUrls.get(x.id),
          pending: false,
          uploading: false,
          progress: 100,
          delete: () => dispatch(deleteTaskAttachment({ taskId, id: x.id }))
        };
      }),
      ...pendingAttachments.map((x) => ({
        id: x.id,
        name: x.name,
        mimeType: x.type ?? 'binary/octet-stream',
        size: x.size,
        url: x.meta.url as string,
        urlExpiresAt: null,
        previewUrl: x.meta.url as string,
        pending: true,
        uploading: !!x.progress?.uploadStarted,
        progress: x.progress?.percentage ?? 0,
        delete: () => uppy.removeFile(x.id, 'removed-by-user')
      }))
    ];
  }, [taskId, attachments, uppyAttachments, previewUrls, uppy, dispatch]);
}

export default memo(function TaskAttachments(props: TaskAttachmentsProps) {
  const { taskId, isNewTask, getPopupContainer } = props;
  const editable = useTypedSelector((state) => {
    const task = taskSelector(state, { taskId });
    return !task.deletedAt && !task.archived && task.permissions.update;
  });
  const attachments = useAttachments(taskId);
  const addAttachment = useAddAttachment(taskId, isNewTask);
  const [tooltipOpen, setTooltipOpen] = useState<string | null>(null);
  const [menuOpen, setMenuOpen] = useState<string | null>(null);

  if (!attachments.length) return null;
  return (
    <div className="task-attachments-container">
      <span>Attachments</span>
      <div className="attachments-list-container">
        {attachments.map((item) => {
          const preview = getPreview({
            mimeType: item.mimeType,
            url: item.previewUrl ?? item.url
          });
          const menuItems: MenuItemType[] = [];
          if (!item.pending) {
            menuItems.push({
              key: 'view-original',
              label: <span>View original</span>,
              onClick: () => {
                window.open(item.url, '_blank');
                setMenuOpen(null);
              }
            });
          }
          if (editable && (!item.pending || item.progress < 100)) {
            menuItems.push({
              key: 'remove',
              label: <span>Remove</span>,
              onClick: () => {
                item.delete();
                setMenuOpen(null);
              }
            });
          }
          return (
            <Tooltip
              key={item.id}
              open={tooltipOpen === item.id}
              onOpenChange={(open) => setTooltipOpen(open ? item.id : null)}
              overlayClassName="toggle_favorite_overlay toggle_favorite_overlay--small"
              overlayStyle={{ position: 'fixed' }}
              title={item.name}
              trigger={menuOpen ? [] : ['hover']}
              placement="topLeft"
              getPopupContainer={getPopupContainer}
            >
              <div>
                <picture>
                  <source type={preview.mimeType} srcSet={preview.url} />
                  {/* in case of unsupported image */}
                  <img alt={item.name} src={FilePlaceholderImg} />
                </picture>
                <Popover
                  placement="rightBottom"
                  trigger={menuItems ? ['click'] : []}
                  open={menuOpen === item.id && !!menuItems.length}
                  onOpenChange={(open) => {
                    setMenuOpen(open ? item.id : null);
                    if (open) setTooltipOpen(null);
                  }}
                  getPopupContainer={getPopupContainer}
                  content={
                    <Menu
                      selectable={false}
                      className="attach-menu-container"
                      items={menuItems}
                    />
                  }
                  overlayClassName="popover-container"
                  overlayInnerStyle={{ padding: 0, width: 200 }}
                >
                  <div
                    className={classNames({
                      'attachment-overlay': true,
                      'attachment-overlay--uploading': item.uploading,
                      'attachment-overlay--empty': !menuItems.length
                    })}
                  >
                    <div className="hover-container">
                      <ActionSvg />
                    </div>
                    <div className="progress-container">
                      <Progress
                        type="circle"
                        percent={item.progress}
                        width={50}
                        strokeWidth={10}
                      />
                    </div>
                  </div>
                </Popover>
              </div>
            </Tooltip>
          );
        })}
        {editable && (
          <Upload
            multiple
            showUploadList={false}
            beforeUpload={(file) => {
              addAttachment(file);
              return false;
            }}
          >
            <div className="add-new-attachments-container">
              <AddNewAttachmentsSvg />
            </div>
          </Upload>
        )}
      </div>
    </div>
  );
});
