import React, { memo, useCallback, useRef, useState, useEffect } from 'react';
import { ReactComponent as CrossSvg } from '@assets/icons/cross.svg';
import { ReactComponent as UploadFilesSvg } from '@assets/icons/upload-campaign-files.svg';
import { Button, Layout } from 'antd';
import TaskBreadcrumbs from '@components/Tasks/TaskModal/TaskBreadcrumbs';
import TaskPriority from '@components/Tasks/TaskModal/TaskPriority';
import TaskAssignees from '@components/Tasks/TaskModal/TaskAssignees';
import TaskDueDate from '@components/Tasks/TaskModal/TaskDueDate';
import TaskStatus from '@components/Tasks/TaskModal/TaskStatus';
import TaskBoard from '@components/Tasks/TaskModal/TaskBoard';
import TaskAssetLink from '@components/Tasks/TaskModal/TaskAssetLink';
import TaskActivity from '@components/Tasks/TaskModal/TaskActivity';
import TaskDescription from '@components/Tasks/TaskModal/TaskDescription';
import TaskName from '@components/Tasks/TaskModal/TaskName';
import { useTypedSelector } from '@hooks';
import { taskSelector } from '@redux/selectors/tasks';
import { TaskItemCampaignDto, TaskItemMemberDto } from '@api/Api';
import { useUpdateTask, useCreateTask, UpdateTaskParams } from '@hooks/tasks';
import { updateNewTask } from '@redux/reducers/tasks';
import useTypedDispatch from '@hooks/useTypedDispatch';
import { createTaskComment } from '@redux/actions/tasks';
import TaskManageAsset from '@components/Tasks/TaskModal/TaskManageAsset';
import { nonNull } from '@helpers/non-null';
import TaskAttachments from '@components/Tasks/TaskModal/TaskAttachments';
import { useTaskAttachmentsUppy } from '@context/TaskAttachmentsUppyContext';
import { useAddAttachment } from '@components/Tasks/TaskModal/hooks/useAddAttachment';
import store from '@redux/store';
import TaskFromCommentIcons from '@components/Tasks/TaskModal/TaskFromCommentIcons';
import TaskCustomFields from '@components/Tasks/TaskModal/TaskCustomFields';
import { useSearchParams } from 'react-router-dom';

interface TaskModalInnerProps<TNewTask extends boolean> {
  isNewTask: TNewTask;
  taskId: string;
  showDropzone: boolean;
  descriptionPlaceholder: string | null;
  taskPosition: 'start-of-list' | 'end-of-list' | null;
  onClose(): void;
  autoAssignMe?: boolean;
  setGoToStep: (v: number) => void;
}

export default memo(function TaskModalInner<TNewTask extends boolean>(
  props: TaskModalInnerProps<TNewTask>
) {
  const {
    isNewTask,
    autoAssignMe,
    taskId,
    showDropzone,
    descriptionPlaceholder,
    taskPosition,
    onClose,
    setGoToStep
  } = props;
  const [searchParams] = useSearchParams();

  const dispatch = useTypedDispatch();
  const nameRequired = useTypedSelector(
    (state) => !taskSelector(state, { taskId }).name.trim()
  );
  const campaignRequired = useTypedSelector(
    (state) => !taskSelector(state, { taskId }).campaign.id
  );
  const [showErrors, setShowErrors] = useState(false);

  const searchParamsRef = useRef(searchParams);
  searchParamsRef.current = searchParams;

  const updateTask = useUpdateTask();
  const createTask = useCreateTask();

  useEffect(() => {
    const startTour = searchParamsRef.current.get('startTour') ?? undefined;
    if (startTour !== 'create-task') return;
    setTimeout(() => {
      setGoToStep(3);
    }, 300);
  }, [setGoToStep]);

  const onUpdate = useCallback(
    (payload: Omit<UpdateTaskParams, 'id'>) => {
      if (isNewTask) dispatch(updateNewTask({ ...payload, id: taskId }));
      else updateTask({ ...payload, id: taskId });
    },
    [isNewTask, dispatch, updateTask, taskId]
  );

  const onCampaignChange = useCallback(
    (value: TaskItemCampaignDto) => {
      dispatch(updateNewTask({ id: taskId, campaign: value }));
    },
    [taskId, dispatch]
  );

  const onComment = useCallback(
    (text: string, mentions: TaskItemMemberDto[]) => {
      dispatch(createTaskComment({ taskId, text, mentions }));
    },
    [taskId, dispatch]
  );

  const uppy = useTaskAttachmentsUppy();
  const onCreate = useCallback(() => {
    setShowErrors(true);
    if (nameRequired) return;
    if (campaignRequired) return;
    const task = taskSelector(store.getState(), { taskId });
    const attachments = uppy
      .getFiles()
      .filter((x) => x.meta.taskId === task.id);
    const promise = createTask({
      ...task,
      taskPosition: taskPosition ?? 'start-of-list'
    });
    promise.unwrap().then((result) => {
      attachments.forEach((x) =>
        uppy.setFileMeta(x.id, {
          taskId: result.task.id,
          readyForUpload: true
        })
      );
      uppy.upload();
    });
    onClose();
  }, [
    taskId,
    nameRequired,
    campaignRequired,
    taskPosition,
    createTask,
    uppy,
    onClose
  ]);

  const resetErrors = useCallback(() => {
    setShowErrors(false);
  }, []);

  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const getPopupContainer = useCallback(
    () => nonNull(scrollContainerRef.current),
    []
  );

  const addAttachment = useAddAttachment(taskId, isNewTask);

  return (
    <>
      <CrossSvg className="close-task-modal" onClick={onClose} />
      <Layout style={{ background: '#fff' }}>
        {showDropzone && (
          <div
            className="drag-attachment-overlay"
            onDrop={(e) => addAttachment(e.dataTransfer.items)}
          >
            <div className="main-drop-container">
              <div>
                <UploadFilesSvg />
                <span>Drop to attach file</span>
              </div>
            </div>
          </div>
        )}
        <div className="task-modal-container">
          <div className="task-main-container">
            <div
              className="task-main-container-inner"
              style={{
                overflowY: 'scroll',
                height: '100%',
                padding: '32px',
                position: 'relative'
              }}
              ref={scrollContainerRef}
            >
              <TaskBreadcrumbs isNewTask={isNewTask} taskId={taskId} />
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <TaskFromCommentIcons
                  taskId={taskId}
                  getPopupContainer={getPopupContainer}
                />
                <TaskName
                  taskId={taskId}
                  onUpdate={onUpdate}
                  isNewTask={isNewTask}
                  showErrors={showErrors}
                  onFocus={resetErrors}
                />
              </div>
              <TaskManageAsset
                isNewTask={isNewTask}
                taskId={taskId}
                showErrors={showErrors}
                onUpdate={onUpdate}
                onResetErrors={resetErrors}
                onCampaignChange={onCampaignChange}
                onClose={onClose}
                getPopupContainer={getPopupContainer}
              />
              <TaskDescription
                taskId={taskId}
                onUpdate={onUpdate}
                descriptionPlaceholder={descriptionPlaceholder}
                getPopupContainer={getPopupContainer}
              />
              <TaskAttachments
                isNewTask={isNewTask}
                taskId={taskId}
                getPopupContainer={getPopupContainer}
              />
              {!isNewTask && (
                <TaskActivity taskId={taskId} onComment={onComment} />
              )}
            </div>
          </div>
          <div className="modal-line" />
          <div className="task-settings-container">
            <div>
              <div>
                <TaskAssetLink isNewTask={isNewTask} taskId={taskId} />
                <TaskBoard taskId={taskId} onUpdate={onUpdate} />
                <TaskStatus taskId={taskId} onUpdate={onUpdate} />
                <TaskDueDate taskId={taskId} onUpdate={onUpdate} />
                <TaskAssignees
                  autoAssignMe={autoAssignMe}
                  taskId={taskId}
                  onUpdate={onUpdate}
                />
                <TaskPriority taskId={taskId} onUpdate={onUpdate} />
                <TaskCustomFields taskId={taskId} />
              </div>
              {isNewTask && (
                <div className="button-container">
                  <Button type="text" onClick={onClose}>
                    Cancel
                  </Button>
                  <Button type="primary" onClick={onCreate}>
                    Create task
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
});
