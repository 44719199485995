import { useTypedSelector } from '@hooks';
import { taskSelector } from '@redux/selectors/tasks';
import classNames from 'classnames';
import React, { memo, useMemo } from 'react';

interface TaskHyperlinkProps {
  taskId: string;
  fieldId: string;
}

export default memo(function TaskHyperlink(props: TaskHyperlinkProps) {
  const { taskId, fieldId } = props;
  const editable = useTypedSelector((state) => {
    const task = taskSelector(state, { taskId });
    return !task.deletedAt && !task.archived && task.permissions.update;
  });
  const field = useTypedSelector((state) =>
    taskSelector(state, { taskId }).customFields.find((x) => x.id === fieldId)
  );
  const link = useMemo(() => {
    if (!field?.value) return null;
    try {
      return JSON.parse(field.value as string) as {
        url: string;
        label: string;
      };
    } catch (err) {
      console.error(err);
      return null;
    }
  }, [field?.value]);
  if (!link || !field) return null;
  return (
    <div
      className={classNames({
        'action-item-container': true,
        'deleted-task-cover-elements': !editable
      })}
      style={{ width: '100%' }}
    >
      <span className="item-name-title">{field.name}</span>
      <div
        className="task-table-hyperlink-label"
        style={{ padding: 0, marginTop: 4 }}
        onClick={(event) => {
          event.stopPropagation();
          let { url } = link;
          if (!url.startsWith('http://') && !url.startsWith('https://')) {
            url = `https://${url}`;
          }
          window.open(url);
        }}
      >
        {link.label || link.url}
      </div>
    </div>
  );
});
