import classNames from 'classnames';
import React, { memo, useMemo } from 'react';
import { ReactComponent as PlusCircleSvg } from '@assets/icons/plus-circle.svg';
import DatePickerPopover from '@components/DatePickerComponent/DatePickerPopover';
import moment from 'moment';
import { UpdateTaskParams } from '@hooks/tasks';
import { useTypedSelector } from '@hooks';
import { taskSelector } from '@redux/selectors/tasks';
import { isDateSameOrBefore } from '@helpers/compareDueDate';
import { useCurrentTime } from '@hooks/useCurrentTime';
import { useCurrentWorkspace } from '@hooks/workspace';
import { useOrganization } from '@components/OrganizationBoundary';

interface TaskDueDateProps {
  taskId: string;
  onUpdate(payload: Omit<UpdateTaskParams, 'id'>): void;
}

const defaultTime = moment().endOf('day');

export default memo(function TaskDueDate(props: TaskDueDateProps) {
  const { taskId, onUpdate } = props;
  const now = useCurrentTime();
  const { currentOrganization } = useOrganization(true);
  const [currentWorkspace] = useCurrentWorkspace(true);
  const editable = useTypedSelector((state) => {
    const task = taskSelector(state, { taskId });
    return (
      !task.deletedAt &&
      !task.archived &&
      task.permissions.update &&
      (!!currentOrganization.entity?.features.contributorsCanSetTaskDeadline ||
        currentWorkspace.role !== 'workspace://contributor')
    );
  });
  const value = useTypedSelector(
    (state) => taskSelector(state, { taskId }).dueDate
  );
  const status = useTypedSelector(
    (state) => taskSelector(state, { taskId }).status
  );
  const isPastDueDate = useMemo(() => {
    return isDateSameOrBefore({
      dateToCompare: value,
      baseDate: now
    });
  }, [now, value]);
  const isTaskDone = status === 'done';
  const m = useMemo(() => (value ? moment(value) : null), [value]);
  const nonDefaultTimeSelected =
    m &&
    (m.hours() !== defaultTime.hours() ||
      m.minutes() !== defaultTime.minutes());
  return (
    <div
      className={classNames({
        'action-item-container': true,
        'deleted-task-cover-elements': !editable
      })}
    >
      <DatePickerPopover
        placement="bottomRight"
        trigger="click"
        showTime
        clearable
        value={m}
        onChange={(value) => {
          const newDate = value?.toISOString() ?? null;
          onUpdate({ dueDate: newDate });
        }}
        timeText="Time due"
        selectTimeText="Add time due"
        disabledDate={(v) => v.isBefore(moment())}
        disabledTime={(v) => v.isBefore(moment())}
      >
        <span className="item-name-title">Due date</span>
        {editable && !m && <PlusCircleSvg />}
        {!!m && (
          <div>
            <span
              className={classNames(
                'time-menu-item',
                !isTaskDone && isPastDueDate && 'overdue-item'
              )}
            >
              {m.format(
                `${nonDefaultTimeSelected ? 'MMM DD, h:mm a' : 'MMM DD'}`
              )}
            </span>
          </div>
        )}
      </DatePickerPopover>
    </div>
  );
});
