import { TaskItemBoardDto } from '@api/Api';
import { hashedColor } from '@helpers/hashedColor';
import { Menu, Popover, Tooltip } from 'antd';
import classNames from 'classnames';
import React, { memo, MouseEventHandler, useState } from 'react';
import { ReactComponent as SelectMenuItemSvg } from '@assets/icons/select-menu-item.svg';
import { useTypedSelector } from '@hooks';
import { taskSelector, taskStateSelector } from '@redux/selectors/tasks';
import { UpdateTaskParams } from '@hooks/tasks';
import { useFetch } from '@hooks/useFetch';
import useTypedDispatch from '@hooks/useTypedDispatch';
import { fetchTaskBoards } from '@redux/actions/tasks';

interface TaskBoardProps {
  taskId: string;
  onUpdate(payload: Omit<UpdateTaskParams, 'id'>): void;
}

export default memo(function TaskBoard(props: TaskBoardProps) {
  const { taskId, onUpdate } = props;
  const dispatch = useTypedDispatch();
  const editable = useTypedSelector((state) => {
    const task = taskSelector(state, { taskId });
    return !task.deletedAt && !task.archived && task.permissions.update;
  });
  const campaignSet = useTypedSelector(
    (state) => !!taskSelector(state, { taskId }).campaign.id
  );
  const value = useTypedSelector(
    (state) => taskSelector(state, { taskId }).board
  );
  const boards = useTypedSelector(
    (state) => taskStateSelector(state, { taskId }).boards.list
  );

  useFetch({
    key: `task-boards-${taskId}`,
    disabled: !campaignSet,
    selector: (state) => taskStateSelector(state, { taskId }).boards.fetch,
    fetch: (fetchType) => dispatch(fetchTaskBoards({ taskId, fetchType }))
  });

  const [open, setOpen] = useState(false);
  return (
    <div
      className={classNames({
        'action-item-container': true,
        'deleted-task-cover-elements': !editable
      })}
    >
      <span className="item-name-title">Task group</span>
      {campaignSet && (
        <Popover
          placement="leftTop"
          trigger={editable ? ['click'] : []}
          open={open}
          onOpenChange={setOpen}
          content={
            <Menu
              className="priority-menu"
              style={{ width: 'auto', minWidth: 240 }}
              selectedKeys={[value.id]}
              items={boards.map((x) => ({
                key: x.id,
                onClick: () => {
                  onUpdate({ board: x });
                  setOpen(false);
                },
                className: 'menu-item',
                label: (
                  <>
                    <div className="task-group-menu-item">
                      <div
                        style={{
                          backgroundColor: hashedColor(x.id, 'taskGroup')
                        }}
                      />
                      <span>{x.name}</span>
                    </div>
                    {x.id === value.id && <SelectMenuItemSvg />}
                  </>
                )
              }))}
            />
          }
          overlayClassName="popover-container task-group_popover-container"
        >
          <TaskBoardInner
            value={value}
            campaignSet={campaignSet}
            menuOpen={open}
          />
        </Popover>
      )}
      {!campaignSet && (
        <TaskBoardInner
          value={value}
          campaignSet={campaignSet}
          menuOpen={open}
        />
      )}
    </div>
  );
});

interface TaskBoardInnerProps {
  campaignSet: boolean;
  menuOpen: boolean;
  value: TaskItemBoardDto;
  onClick?: MouseEventHandler<HTMLDivElement>;
}
function TaskBoardInner({
  value,
  campaignSet,
  menuOpen,
  onClick
}: TaskBoardInnerProps) {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  if (menuOpen && tooltipOpen) setTooltipOpen(false);
  return (
    <div
      onClick={onClick}
      className={classNames({
        'disabled-container': !campaignSet
      })}
    >
      <div className="task-group-container">
        <div
          style={{
            backgroundColor: hashedColor(value.id, 'taskGroup')
          }}
        />
        <Tooltip
          open={tooltipOpen}
          onOpenChange={setTooltipOpen}
          overlayClassName={classNames({
            'long-text-hint': campaignSet,
            'task-group-settings-hint': !campaignSet
          })}
          title={
            campaignSet
              ? value.name
              : 'Campaign must be assigned first to add a task group'
          }
          trigger={menuOpen ? [] : ['hover']}
          placement="topRight"
        >
          <span>{value.name}</span>
        </Tooltip>
      </div>
    </div>
  );
}
