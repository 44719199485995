import { useTypedSelector } from '@hooks';
import { taskSelector } from '@redux/selectors/tasks';
import { Tooltip, TooltipProps } from 'antd';
import React, { memo } from 'react';
import { ReactComponent as TaskCommentIconSvg } from '@assets/icons/task-from-comment-icon.svg';
import { ReactComponent as AnnotationIconSvg } from '@assets/icons/task-annotation-icon.svg';

interface TaskFromCommentIconsProps {
  taskId: string;
  getPopupContainer: TooltipProps['getPopupContainer'];
}

export default memo(function TaskFromCommentIcons(
  props: TaskFromCommentIconsProps
) {
  const { taskId, getPopupContainer } = props;
  const comment = useTypedSelector(
    (state) => taskSelector(state, { taskId }).assetVersionComment
  );
  return (
    <>
      {comment?.hasAnnotations === true && (
        <Tooltip
          overlayClassName="task-group-settings-hint"
          title="This task has an annotation"
          trigger="hover"
          placement="top"
          getPopupContainer={getPopupContainer}
        >
          <AnnotationIconSvg style={{ marginRight: 12, cursor: 'pointer' }} />
        </Tooltip>
      )}
      {comment?.hasAnnotations === false && (
        <Tooltip
          overlayClassName="task-group-settings-hint"
          title="This task was created from a comment"
          trigger="hover"
          placement="top"
          getPopupContainer={getPopupContainer}
        >
          <TaskCommentIconSvg style={{ marginRight: 12, cursor: 'pointer' }} />
        </Tooltip>
      )}
    </>
  );
});
