import React from 'react';
import { Button, Modal } from 'antd';

import './TaskRecoveryModal.less';

interface ITaskRecoveryModal {
  open: boolean;
  oldVersionAsset: { name: string; id: string; extension: string };
  currentVersionAsset: { name: string; id: string; extension: string };
  handleTaskRecovery: () => void;
  onClose: () => void;
}

function TaskRecoveryModal({
  open,
  oldVersionAsset,
  currentVersionAsset,
  handleTaskRecovery,
  onClose
}: ITaskRecoveryModal) {
  return (
    <Modal
      width={528}
      open={open}
      destroyOnClose
      onCancel={onClose}
      footer={null}
      centered={true}
    >
      <div className="task-recovery-modal">
        <span>Task recovery</span>
        <div>
          This is a previous task belonging to an older version of{' '}
          <span>{`${oldVersionAsset?.name}.${oldVersionAsset?.extension}`}</span>
          . Do you want to add this task to the current version of{' '}
          <span>{`${currentVersionAsset?.name}.${currentVersionAsset?.extension}`}</span>
          ?
        </div>
        <div className="recovery-button-container">
          <Button type="text" onClick={onClose}>
            No
          </Button>
          <Button type="primary" onClick={handleTaskRecovery}>
            Yes
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default TaskRecoveryModal;
