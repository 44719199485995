import { assetPreviewThumbnails } from '@helpers/assetPreviewThumbnails';
import {
  Dropdown,
  Menu,
  Input,
  InputRef,
  Tooltip,
  Skeleton,
  DropDownProps,
  Button
} from 'antd';
import classNames from 'classnames';
import { ReactComponent as PlusSvg } from '@assets/icons/plus.svg';
import React, { memo, useMemo, useRef, useState, useCallback } from 'react';
import { ReactComponent as DownArrowSvg } from '@assets/icons/arrow-down.svg';
import { UpdateTaskParams } from '@hooks/tasks';
import { useTypedSelector } from '@hooks';
import { taskSelector, taskStateSelector } from '@redux/selectors/tasks';
import { useFetch } from '@hooks/useFetch';
import useTypedDispatch from '@hooks/useTypedDispatch';
import { fetchTaskAssets } from '@redux/actions/tasks';
import { loadMoreTaskAssets, setTaskAssetsSearch } from '@redux/reducers/tasks';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { InfiniteScroll } from '@components/InfiniteScroll/InfiniteScroll';
import { toggleUploadAssetModal } from '@redux/actions/modalAction';
import { useCurrentWorkspace } from '@hooks/workspace';

interface TaskAssetSelectorProps {
  taskId: string;
  onUpdate(payload: Omit<UpdateTaskParams, 'id'>): void;
  getPopupContainer: DropDownProps['getPopupContainer'];
}

export default memo(function TaskAssetSelector(props: TaskAssetSelectorProps) {
  const { taskId, onUpdate, getPopupContainer } = props;
  const dispatch = useTypedDispatch();
  const [open, setOpen] = useState(false);
  const [currentWorkspace] = useCurrentWorkspace(true);
  const workspaceId = currentWorkspace.id;
  const editable = useTypedSelector((state) => {
    const task = taskSelector(state, { taskId });
    return !task.deletedAt && !task.archived && task.permissions.update;
  });
  const value = useTypedSelector(
    (state) => taskSelector(state, { taskId }).asset
  );
  const assetName = value
    ? `${value.selectedVersion.name}.${value.selectedVersion.extension}`
    : '';
  const campaignSet = useTypedSelector(
    (state) => !!taskSelector(state, { taskId }).campaign.id
  );
  const assets = useTypedSelector(
    (state) => taskStateSelector(state, { taskId }).assets.list
  );
  const hasMore = useTypedSelector(
    (state) => taskStateSelector(state, { taskId }).assets.hasMore
  );
  const searchQuery = useTypedSelector(
    (state) => taskStateSelector(state, { taskId }).assets.searchQuery
  );

  const loading = useFetch({
    key: `task-assets-${taskId}`,
    disabled: !campaignSet,
    selector: (state) => taskStateSelector(state, { taskId }).assets.fetch,
    fetch: (fetchType) => dispatch(fetchTaskAssets({ taskId, fetchType }))
  });

  const onSearch = useCallback(
    (value: string) => {
      dispatch(
        setTaskAssetsSearch({
          value,
          taskId
        })
      );
    },
    [taskId, dispatch]
  );

  const onLoadMore = useCallback(() => {
    dispatch(loadMoreTaskAssets({ taskId }));
  }, [taskId, dispatch]);

  const searchQueryRef = useRef(searchQuery);
  searchQueryRef.current = searchQuery;
  const inputRef = useRef<InputRef>(undefined as never);
  const onOpenChange = useCallback(
    (open: boolean) => {
      setOpen(open);
      if (open) {
        inputRef.current.focus({ cursor: 'end' });
      } else {
        inputRef.current.blur();
      }
      if (!open && searchQueryRef.current) {
        dispatch(
          setTaskAssetsSearch({
            value: '',
            taskId
          })
        );
      }
    },
    [dispatch, taskId]
  );

  const menuItems = useMemo(() => {
    const items: ItemType[] = assets.map((x) => {
      const version = x.asset.versions[0];
      const preview = assetPreviewThumbnails({
        url: version.metadata.preview?.url || '',
        status: version.metadata.preview?.status || '',
        type: version.metadata.type || '',
        format: 'img',
        showPendingPlaceholder: true
      });
      return {
        key: x.id,
        className: 'assets-menu-item',
        onClick: () => {
          onUpdate({
            asset: {
              id: x.id,
              isDeleted: false,
              lastVersion: version,
              selectedVersion: version,
              path: x.path
            }
          });
          setOpen(false);
        },
        label: (
          <div>
            <img src={preview} alt="Asset Preview" />
            <span>
              {version.name}.{version.extension}
            </span>
          </div>
        )
      };
    });
    if (hasMore || (loading && !assets.length)) {
      items.push({
        key: 'loader',
        className: 'assets-menu-item',
        label: (
          <InfiniteScroll
            loading={loading}
            hasMore={hasMore}
            next={onLoadMore}
            alwaysShowLoader
            loader={
              <div>
                <Skeleton.Avatar shape="square" />
                <Skeleton.Input size="small" />
              </div>
            }
          />
        )
      });
    } else if (!assets.length) {
      items.push({
        key: 'empty',
        onClick: () => {
          dispatch(toggleUploadAssetModal({ workspaceId, visible: true }));
        },
        label: (
          <Button className="upload-asset-btn" type="primary">
            Upload media
          </Button>
        )
      });
    }
    if (assets.length)
      items.push({
        key: 'upload',
        className: 'assets-menu-item',
        onClick: () => {
          dispatch(toggleUploadAssetModal({ workspaceId, visible: true }));
        },
        label: (
          <div className="extra-menu-item">
            <PlusSvg /> upload media
          </div>
        )
      });
    return items;
  }, [assets, onUpdate, loading, hasMore, onLoadMore, dispatch, workspaceId]);

  return (
    <div id="SELECT-TASK-ASSET-INPUT">
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <span>
          Media <span style={{ color: '#969696' }}>(optional)</span>
        </span>
        {value && (
          <span
            style={{ color: '#969696', cursor: 'pointer' }}
            onClick={() => {
              onUpdate({ asset: null });
            }}
          >
            Clear
          </span>
        )}
      </div>
      <Dropdown
        open={open}
        onOpenChange={onOpenChange}
        overlay={
          <Menu
            style={{
              padding: 24,
              maxHeight: 260,
              width: 320
            }}
            selectedKeys={value ? [value.id] : undefined}
            className="assets-menu-list"
            items={menuItems}
          />
        }
        trigger={editable && campaignSet ? ['click'] : []}
        getPopupContainer={getPopupContainer}
      >
        <div>
          <Tooltip
            title="Campaign must be assigned first to add an asset"
            trigger={editable && !campaignSet ? ['hover'] : []}
            placement="topRight"
            overlayClassName="long-text-hint"
          >
            <Input
              ref={inputRef}
              placeholder="Media name"
              suffix={
                <DownArrowSvg
                  style={
                    !open
                      ? { transform: 'rotateZ(0deg)' }
                      : { transform: 'rotateZ(180deg)' }
                  }
                  className={classNames({
                    'manage-assets-arrow': true,
                    'manage-assets-arrow-open': open
                  })}
                />
              }
              value={open ? searchQuery : assetName}
              className={classNames({
                'menu-filter-item': true,
                'deleted-task-cover-elements': !editable
              })}
              onChange={(e) => onSearch(e.target.value)}
              readOnly={!editable || !campaignSet}
            />
          </Tooltip>
        </div>
      </Dropdown>
    </div>
  );
});
