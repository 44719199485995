import classNames from 'classnames';
import React, { memo, useRef, useState } from 'react';
import { ReactComponent as PreviousManageSvg } from '@assets/icons/previous-task-manage-icon.svg';
import { ReactComponent as ActionSvg } from '@assets/icons/action.svg';
import {
  Dropdown,
  DropDownProps,
  Input,
  Menu,
  Popover,
  Tooltip,
  Upload
} from 'antd';
import { TaskItemAssetVersionDto, TaskItemCampaignDto } from '@api/Api';
import { UpdateTaskParams, useDeleteTask, useRestoreTask } from '@hooks/tasks';
import { useTypedSelector } from '@hooks';
import { taskSelector, taskStateSelector } from '@redux/selectors/tasks';
import { useFetch } from '@hooks/useFetch';
import useTypedDispatch from '@hooks/useTypedDispatch';
import { fetchTaskCampaigns } from '@redux/actions/tasks';
import TaskAssetSelector from '@components/Tasks/TaskModal/TaskAssetSelector';
import { useAddAttachment } from '@components/Tasks/TaskModal/hooks/useAddAttachment';
import TaskRecoveryModal from '@components/Modals/TaskRecoveryModal';
import { MenuItemType } from 'antd/lib/menu/hooks/useItems';
import LottieComponent from '@components/Lottie';
import TaskInputError from '@components/Tasks/TaskModal/components/TaskInputError/TaskInputError';

interface TaskManageAssetProps {
  taskId: string;
  isNewTask: boolean;
  showErrors: boolean;
  onUpdate(payload: Omit<UpdateTaskParams, 'id'>): void;
  onResetErrors: () => void;
  onCampaignChange?: (value: TaskItemCampaignDto) => void;
  onClose: () => void;
  getPopupContainer: DropDownProps['getPopupContainer'];
}

export default memo(function TaskManageAsset({
  taskId,
  isNewTask,
  showErrors,
  onUpdate,
  onResetErrors,
  onCampaignChange,
  onClose,
  getPopupContainer
}: TaskManageAssetProps) {
  const dispatch = useTypedDispatch();
  const deleteTask = useDeleteTask();
  const restoreTask = useRestoreTask();
  const canUpdate = useTypedSelector((state) => {
    const task = taskSelector(state, { taskId });
    return task.permissions.update;
  });
  const canDelete = useTypedSelector((state) => {
    const task = taskSelector(state, { taskId });
    return task.permissions.delete;
  });
  const deleted = useTypedSelector(
    (state) => !!taskSelector(state, { taskId }).deletedAt
  );
  const archived = useTypedSelector(
    (state) => taskSelector(state, { taskId }).archived
  );
  const campaign = useTypedSelector(
    (state) => taskSelector(state, { taskId }).campaign
  );
  const campaigns = useTypedSelector((state) =>
    taskStateSelector(state, { taskId }).campaigns.list.filter((it) => !it.demo)
  );
  const showRequired = !campaign.id && showErrors;

  const asset = useTypedSelector(
    (state) => taskSelector(state, { taskId }).asset
  );

  const [open, setOpen] = useState(showRequired || isNewTask);
  const prevShowRequired = useRef(showRequired);
  if (showRequired !== prevShowRequired.current) {
    prevShowRequired.current = showRequired;
    if (showRequired) setOpen(true);
  }

  useFetch({
    key: `task-campaigns-${taskId}`,
    disabled: !isNewTask,
    selector: (state) => taskStateSelector(state, { taskId }).campaigns.fetch,
    fetch: (fetchType) => dispatch(fetchTaskCampaigns({ taskId, fetchType }))
  });

  const addAttachment = useAddAttachment(taskId, isNewTask);

  const [recoveryOpen, setRecoveryOpen] =
    useState<TaskItemAssetVersionDto | null>(null);

  const [actionsOpen, setActionsOpen] = useState(false);
  const actions: MenuItemType[] = [];

  if (!isNewTask && canDelete) {
    if (!deleted) {
      actions.push({
        key: 'delete',
        label: 'Delete',
        onClick: () => {
          setActionsOpen(false);
          setTimeout(() => {
            deleteTask({ id: taskId });
          }, 500);
        }
      });
    } else {
      actions.push({
        key: 'restore',
        label: 'Restore',
        onClick: () => {
          setActionsOpen(false);
          setTimeout(() => {
            restoreTask({ id: taskId });
          }, 500);
        }
      });
      actions.push({
        key: 'permanent-delete',
        label: 'Delete forever',
        onClick: () => {
          setActionsOpen(false);
          onClose();
          setTimeout(() => {
            deleteTask({ id: taskId, permanent: true });
          }, 500);
        }
      });
    }
  }

  return (
    <>
      <div className="task-manage-container">
        <div
          className={classNames({
            'manage-asset-button': true,
            'deleted-task-cover-elements': deleted || !canUpdate,
            'deleted-task-elements-color': deleted
          })}
          onClick={() => {
            if (!archived) {
              setOpen(!open);
              if (open) onResetErrors();
            } else if (asset) {
              setRecoveryOpen(asset.selectedVersion);
            }
          }}
        >
          {!archived && (
            <>
              <LottieComponent
                view="arrow"
                className={classNames({
                  'manage-assets-arrow': true,
                  'manage-assets-arrow-open': open
                })}
              />
              {/* <DownArrowSvg
                
              /> */}
              <span>Manage media</span>
            </>
          )}
          {archived && (
            <>
              <PreviousManageSvg />
              <span>Move to current tasks</span>
            </>
          )}
        </div>
        {!archived && (
          <div
            className={classNames({
              'deleted-task-cover-elements': deleted || !canUpdate,
              'deleted-task-elements-color': deleted,
              'attach-file': true
            })}
          >
            <LottieComponent className="paper-clip-icon" view="paperClip" />
            <Upload
              multiple
              showUploadList={false}
              beforeUpload={(file) => {
                addAttachment(file);
                return false;
              }}
            >
              <span
                className={classNames({
                  'deleted-task-elements-color': deleted
                })}
              >
                Attach file
              </span>
            </Upload>
          </div>
        )}
        {!!actions.length && (
          <>
            <div className="action-line" />
            <Popover
              open={actionsOpen}
              onOpenChange={setActionsOpen}
              content={<Menu items={actions} />}
              placement="right"
              trigger="click"
              overlayClassName="popover-container"
              overlayStyle={{ width: 200 }}
            >
              <div className="action-icon-content" style={{ marginLeft: 0 }}>
                <ActionSvg />
              </div>
            </Popover>
          </>
        )}
      </div>
      {open && (
        <div className="manage-assets-container">
          <div id="SELECT-TASK-CAMPAIGN-INPUT">
            <span>Campaign</span>
            <Dropdown
              onOpenChange={(open) => {
                if (open) onResetErrors();
              }}
              overlay={
                <Menu
                  style={{ padding: 24 }}
                  selectedKeys={campaign ? [campaign.id] : undefined}
                  items={
                    onCampaignChange
                      ? campaigns.map((x) => ({
                          key: x.id,
                          onClick: () => onCampaignChange(x),
                          label: (
                            <Tooltip
                              overlayClassName="long-text-hint"
                              title={x.name}
                              trigger="hover"
                              placement="topLeft"
                              overlayInnerStyle={{ maxWidth: 200 }}
                              getPopupContainer={getPopupContainer}
                            >
                              <span className="campaign-menu-item">
                                {x.name}
                              </span>
                            </Tooltip>
                          )
                        }))
                      : []
                  }
                />
              }
              trigger={isNewTask ? ['click'] : []}
              placement="bottomLeft"
              getPopupContainer={getPopupContainer}
            >
              <Input
                readOnly
                value={campaign.name}
                className="menu-filter-item"
                style={{ height: 54 }}
                placeholder="Campaign name"
                status={showRequired ? 'error' : undefined}
              />
            </Dropdown>
            {showRequired && <TaskInputError text="Select a campaign" />}
          </div>
          <TaskAssetSelector
            taskId={taskId}
            onUpdate={onUpdate}
            getPopupContainer={getPopupContainer}
          />
        </div>
      )}
      {!!asset && (
        <TaskRecoveryModal
          open={!!recoveryOpen}
          oldVersionAsset={recoveryOpen ?? asset.selectedVersion}
          currentVersionAsset={asset.lastVersion}
          handleTaskRecovery={() => {
            setRecoveryOpen(null);
            onUpdate({
              asset: {
                ...asset,
                selectedVersion: asset.lastVersion
              }
            });
          }}
          onClose={() => setRecoveryOpen(null)}
        />
      )}
    </>
  );
});
