import React, { useMemo, useState, useCallback, useRef } from 'react';
import { Avatar, Button, Row, Col, Tooltip, Popover } from 'antd';
import { useEditor, EditorContent, ReactRenderer } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Placeholder from '@tiptap/extension-placeholder';
import Link from '@tiptap/extension-link';
import Mention from '@tiptap/extension-mention';
import MentionList from '@components/RichTextForm/components/MentionList';
import { ReactComponent as DoggySvg } from '@assets/icons/doggy.svg';
import { ReactComponent as EditorBoldSvg } from '@assets/icons/editor-bold.svg';
import { ReactComponent as EditorItalicSvg } from '@assets/icons/editor-italic.svg';
import { ReactComponent as EditorLinkSvg } from '@assets/icons/editor-link.svg';
import { ReactComponent as EditorLineThroughSvg } from '@assets/icons/editor-line-through.svg';
import { MemberDto } from '@api/Api';
import LinkModalContent from '@components/RichTextForm/components/LinkModalContent';
import './RichTextForm.less';
import {
  MentionListRef,
  RenderMentionListProps
} from '@components/RichTextForm/components/MentionList/MentionList';
import { nonNull } from '@helpers/non-null';
import { Editor } from '@tiptap/core';
import classNames from 'classnames';

interface IRichTextFormProps {
  theme?: 'default' | 'dark';
  value?: string;
  isCleanAfterSubmit?: boolean;
  isRequired?: boolean;
  isShowButtons?: boolean;
  userPicture?: string | typeof import('*.svg');
  placeholder?: string;
  mentions: MemberDto[];
  onMention: (searchQuery: string) => void;
  buttonSubmitText?: string | React.ReactElement;
  onCancel?(): void;
  onChange?(value: string): void;
  onSubmit(
    text: string,
    mentions: { add: MemberDto[]; remove: string[] }
  ): void | Promise<void>;
  submitOnBlur?: boolean;
  hideSubmitButton?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
}

function getMentionIds(editor: Editor | null) {
  const textJson = editor?.getJSON()?.content;
  const result = new Set<string>();
  textJson?.forEach((item) => {
    const allMentions: string[] =
      item.content
        ?.filter((el) => el.type === 'mention')
        ?.map((el) => el?.attrs?.id)
        ?.filter((el) => Boolean(el)) || [];
    allMentions.forEach((id) => result.add(id));
  });
  return result;
}

function RichTextForm(props: IRichTextFormProps) {
  const {
    theme,
    value,
    isRequired,
    isCleanAfterSubmit,
    isShowButtons,
    userPicture,
    placeholder,
    buttonSubmitText,
    onCancel,
    onSubmit,
    onChange,
    submitOnBlur,
    onFocus,
    onBlur,
    hideSubmitButton,
    mentions,
    onMention
  } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isShowLinkModal, setIsShowLinkModal] = useState<boolean>(false);

  const ref = useRef<HTMLDivElement>(null);
  const mentionsVisible = useRef(false);
  const mentionsRenderer =
    useRef<ReactRenderer<MentionListRef, RenderMentionListProps>>();
  const mentionsCloseTimeoutId = useRef<any>();

  const allMentions = useRef(
    new Map<string, MemberDto>(mentions.map((x) => [x.id, x]))
  );
  const prevMentions = useRef(mentions);
  if (mentions !== prevMentions.current) {
    prevMentions.current = mentions;
    mentions.forEach((x) => allMentions.current.set(x.id, x));
    mentionsRenderer.current?.updateProps({ mentions });
  }

  const editor = useEditor(
    {
      extensions: [
        StarterKit,
        Placeholder.configure({
          placeholder
        }),
        Link.configure({
          autolink: false
        }),
        Mention.configure({
          HTMLAttributes: {
            class: 'b-suggestion-user'
          },
          suggestion: {
            render: () => {
              return {
                onStart: (props) => {
                  if (mentionsCloseTimeoutId.current) {
                    clearTimeout(mentionsCloseTimeoutId.current);
                  }
                  mentionsVisible.current = true;
                  if (!mentionsRenderer.current) {
                    const initialProps: RenderMentionListProps = {
                      ...props,
                      visible: true,
                      container: nonNull(ref.current),
                      mentions: prevMentions.current,
                      onMention
                    };
                    mentionsRenderer.current = new ReactRenderer(MentionList, {
                      props: initialProps,
                      editor: props.editor
                    });
                  } else {
                    mentionsRenderer.current?.updateProps({
                      ...props,
                      mentions: prevMentions.current,
                      visible: true
                    });
                  }
                },
                onUpdate(props) {
                  if (mentionsCloseTimeoutId.current) {
                    clearTimeout(mentionsCloseTimeoutId.current);
                  }
                  mentionsRenderer.current?.updateProps({
                    ...props,
                    mentions: prevMentions.current,
                    visible: true
                  });
                },
                onKeyDown(props) {
                  if (props.event.key === 'Escape') {
                    mentionsVisible.current = false;
                    mentionsRenderer.current?.updateProps({ visible: false });
                    return true;
                  }
                  return (
                    mentionsRenderer.current?.ref?.onKeyDown(props.event) ??
                    false
                  );
                },
                onExit() {
                  mentionsRenderer.current?.updateProps({ visible: false });
                  mentionsVisible.current = false;
                }
              };
            }
          }
        })
      ],
      content: value,
      onUpdate: (data) => onChange && onChange(data.editor?.getHTML() || ''),
      onBlur: () => {
        mentionsCloseTimeoutId.current = setTimeout(() => {
          mentionsRenderer.current?.updateProps({ visible: false });
        }, 300);
      },
      onFocus: () => {
        if (mentionsCloseTimeoutId.current) {
          clearTimeout(mentionsCloseTimeoutId.current);
        }
        if (mentionsVisible.current) {
          mentionsRenderer.current?.updateProps({
            visible: true,
            mentions: prevMentions.current
          });
          mentionsVisible.current = !!mentionsRenderer.current;
        }
      },
      onDestroy: () => {
        mentionsRenderer.current?.destroy();
        mentionsRenderer.current = undefined;
        mentionsVisible.current = false;
      }
    },
    []
  );

  const prevValue = useRef(value);
  if (editor && value !== undefined && value !== prevValue.current) {
    prevValue.current = value;
    editor.commands.setContent(value);
  }

  const defaultMentionIds = useMemo(() => getMentionIds(editor), [editor]);
  const [focus, setFocus] = useState(editor?.isFocused ?? false);
  const focustTimeoutId = useRef<any>(null);

  const setLink = useCallback(
    ({ text, href }: { href: string; text: string }) => {
      if (editor) {
        setIsShowLinkModal(false);
        editor.chain().focus().extendMarkRange('link').setLink({ href }).run();
        editor.commands.insertContent(text);
      }
    },
    [editor]
  );

  const onSubmitForm = async () => {
    if (isRequired && !editor?.getText()) {
      return;
    }
    if (onSubmit) {
      try {
        setIsLoading(true);
        const text = editor?.getHTML() ?? '';
        const currentMentionIds = getMentionIds(editor);
        const newMentions = [...currentMentionIds]
          .filter(
            (id) => !defaultMentionIds.has(id) && allMentions.current.has(id)
          )
          .map((id) => nonNull(allMentions.current.get(id)));
        const removedMentionIds = [...defaultMentionIds].filter(
          (id) => !currentMentionIds.has(id)
        );
        await onSubmit(text, { add: newMentions, remove: removedMentionIds });
      } finally {
        setIsLoading(false);
        if (
          document.activeElement &&
          ref.current?.contains(document.activeElement)
        ) {
          (document.activeElement as any).blur();
        }
        if (isCleanAfterSubmit && editor) {
          editor.commands.clearContent();
        }
      }
    }
  };

  const editorButtons = useMemo(
    () => [
      {
        icon: <EditorBoldSvg />,
        title: 'Bold',
        isActive: 'bold',
        callBack: () => editor && editor.chain().focus().toggleBold().run()
      },
      {
        icon: <EditorItalicSvg />,
        title: 'Italic',
        isActive: 'italic',
        callBack: () => editor && editor.chain().focus().toggleItalic().run()
      },
      {
        icon: <EditorLineThroughSvg />,
        title: 'Strike',
        isActive: 'strike',
        callBack: () => editor && editor.chain().focus().toggleStrike().run()
      }
    ],
    [editor]
  );

  const renderButtons = useMemo(
    () =>
      editorButtons.map((item, index) => (
        <Col key={`rich_text_form_btn-icon-${index}`}>
          <Tooltip
            placement="top"
            title={item.title}
            overlayClassName="rich_text_form_tooltip"
          >
            <Button
              htmlType="button"
              className={`rich_text_form_btn-icon ${
                editor && editor.isActive(item.isActive) ? 'is-active' : ''
              }`}
              onClick={item.callBack}
            >
              {item.icon}
            </Button>
          </Tooltip>
        </Col>
      )),
    [editor, editorButtons]
  );

  return (
    <div
      ref={ref}
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      tabIndex={0}
      className={classNames({
        rich_text_form_container: true,
        'is-focused-editor': focus,
        [`b-theme-${theme}`]: theme !== 'default'
      })}
      style={{ position: 'relative' }}
      onFocus={(e) => {
        if (focustTimeoutId.current) clearTimeout(focustTimeoutId.current);
        if (!focus) {
          setFocus(true);
          if (e.target === ref.current) editor?.commands.focus('end');
          if (onFocus) onFocus();
        }
      }}
      onBlur={() => {
        if (focus) {
          focustTimeoutId.current = setTimeout(() => {
            setFocus(false);
            if (onBlur) onBlur();
            if (submitOnBlur) onSubmitForm();
          }, 0);
        }
      }}
    >
      {userPicture && (
        <Avatar
          className="rich_text_form_avatar"
          shape="circle"
          size="default"
          src={userPicture}
        />
      )}
      <div className="rich_text_form_field">
        <EditorContent
          className="rich_text_form_field-editor"
          editor={editor}
        />
      </div>
      <Row
        gutter={8}
        className={classNames({
          rich_text_form_bottom: true,
          'rich_text_form_bottom--visible': focus
        })}
        wrap={false}
        justify="space-between"
        align="middle"
      >
        <Col flex="auto">
          <Row gutter={16} wrap={false} align="middle">
            {isShowButtons && (
              <>
                <Col className="rich_text_form_bottom-item">
                  <Row gutter={6} wrap={false} align="middle">
                    {renderButtons}
                  </Row>
                </Col>
                <Col className="rich_text_form_bottom-item">
                  <Tooltip
                    placement="top"
                    title="Link"
                    overlayClassName="rich_text_form_tooltip"
                  >
                    <Popover
                      content={
                        <LinkModalContent
                          onSubmit={setLink}
                          onClose={() => {
                            setIsShowLinkModal(false);
                            editor?.commands.focus('end');
                          }}
                        />
                      }
                      visible={isShowLinkModal}
                      onVisibleChange={(value) => setIsShowLinkModal(value)}
                      trigger={['click']}
                      placement="right"
                      overlayClassName="popover-container"
                    >
                      <Button
                        htmlType="button"
                        className="rich_text_form_btn-icon"
                      >
                        <EditorLinkSvg />
                      </Button>
                    </Popover>
                  </Tooltip>
                </Col>
              </>
            )}
            <Col className="rich_text_form_bottom-item">
              <Row gutter={8} wrap={false} align="middle">
                <Col>
                  <Tooltip
                    placement="top"
                    title="Mention someone"
                    overlayClassName="rich_text_form_tooltip"
                  >
                    <Button
                      htmlType="button"
                      className="rich_text_form_btn-icon b-mention"
                      onClick={() => {
                        if (!mentionsVisible.current) {
                          mentionsVisible.current = true;
                          editor?.commands.focus('end');
                          editor?.commands.exitCode();
                          editor?.commands.insertContent(' @');
                        }
                        editor?.commands.focus('end');
                      }}
                    >
                      <DoggySvg />
                    </Button>
                  </Tooltip>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col>
          <Row wrap={false} align="middle" justify="end" gutter={12}>
            {onCancel && (
              <Col>
                <Button
                  type="text"
                  className="rich_text_form_btn"
                  htmlType="button"
                  onClick={onCancel}
                  disabled={isLoading}
                >
                  Cancel
                </Button>
              </Col>
            )}
            {!hideSubmitButton && (
              <Col>
                <Button
                  type="primary"
                  className="rich_text_form_btn"
                  htmlType="submit"
                  loading={isLoading}
                  onClick={onSubmitForm}
                >
                  {buttonSubmitText}
                </Button>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </div>
  );
}

RichTextForm.defaultProps = {
  theme: 'default',
  buttonSubmitText: 'submit',
  isRequired: false,
  isCleanAfterSubmit: false,
  isShowButtons: false,
  userPicture: null,
  placeholder: null,
  onCancel: null,
  onChange: null
};

export default RichTextForm;
